import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../reducers/authSlice';

export const OrderToken = () => {
  const { orderNumber, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setToken({
      orderNumber: orderNumber ?? '',
      token: token ?? '',
    });

    navigate(`/return/${orderNumber}`);
  }, [navigate, orderNumber, token]);

  return <div>{orderNumber}</div>;
};
