import { Table, TableRow, TableCell, TableBody, Card, CardHeader, CardContent, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { OrderReturn } from '../../../store/orderReturnsApi';
import { useIntl } from 'react-intl';

export const OrderReturnSummary = ({ orderReturn, isLoading }: { orderReturn: OrderReturn | undefined; isLoading: boolean }) => {
  //  if (!orderReturn) return null;

  return (
    <Card>
      <CardHeader title="Return Summary"></CardHeader>
      <CardContent>
        <Table sx={{ '& td': { border: 0 } }} padding="none">
          <TableBody>
            <SummaryRow title="Merchandise total" value={orderReturn?.totalMerchandise ?? 0} isLoading={isLoading}/>
            <SummaryRow title="Discount" value={orderReturn?.totalDiscount ?? 0} isLoading={isLoading}/>
            <SummaryRow title="Sub total" value={orderReturn?.totalSubtotal ?? 0} isLoading={isLoading}/>
            <SummaryRow title="Total" value={orderReturn?.total ?? 0} isTotal={true} isLoading={isLoading} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const SummaryRow = ({ title, value, isTotal = false, isLoading = false }) => {
  const { formatNumber } = useIntl();
  const formatedPrice = formatNumber(value, { style: "currency", currency: "CAD" })
  return (
    <TableRow>
      <TableCell align="left">
        <Typography children={title} variant={isTotal ? 'h3' : undefined} />
      </TableCell>
      <TableCell align="right">{isLoading ? <CircularProgress size={16} /> :
        <Typography children={formatedPrice} variant={isTotal ? 'h3' : undefined} />}
      </TableCell>
    </TableRow>
  );
};
