import { Box, Paper, Checkbox, Typography, CircularProgress, Select, MenuItem, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { LookupValue, useGetLookupQuery } from '../../../store/orderReturnsApi';
import { useIntl } from 'react-intl';

export const OrderItemsForReturn = ({ items, onSelected, onChanged, isLoading = true }) => {
  const { data: returnReasons } = useGetLookupQuery({ lookupName: "OrderReturnReasons" });
  const intl = useIntl()

  if (isLoading) return (
    <Box textAlign="center">
      <CircularProgress size={50} />
    </Box>
    );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {items?.map((item) => (
            <TableRow key={`${item.id}${item.isUnavailable}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell padding="checkbox">
                <Checkbox color="primary" checked={item.quantity > 0} value={item.id} disabled={item.isUnavailable} onChange={onSelected} />
              </TableCell>
              <TableCell component="th" scope="row" size="small">
                <Box
                  component="img"
                  sx={{
                    maxHeight: { xs: 233, md: 100 },
                  }}
                  alt={item.productDisplayName}
                  src={`${item.imageUrl}`}
                />
              </TableCell>
              <TableCell align="left">
                <Typography component="div">
                  <Box sx={{ fontWeight: 'bold' }}>{item.productDisplayName}</Box>
                </Typography>
                {item.productPrice} / each
                <br />
                {item.isUnavailable && item.unavailableReason && (
                  <Typography
                    color="white"
                    bgcolor="error.main"
                    align="center"
                    children={item.unavailableReason}
                    sx={{ fontSize: 11, borderRadius: 2, float: 'left', p: 0.5, mt: 2 }}
                  />
                )}
              </TableCell>
              <TableCell align="left">
                {!item.isUnavailable && <Box>
                  <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    <Box>
                      <TextField
                        id="outlined-quanity"
                        label="Quantity"
                        value={item.quantity}
                        type="number"
                        size="small"
                        sx={{ width: 80 }}
                        onChange={(newValue) => onChanged(item, 'quantity', newValue)}
                        InputProps={{
                          inputProps: { 
                              max: item.availableQuantity, min: 0 
                          }
                      }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box>/{item.availableQuantity}</Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Select size="small"
                      defaultValue="none"
                      value={item.reason ?? "none"} onChange={(newValue) => onChanged(item, 'reason', newValue)}>
                      <MenuItem value="none">
                        <em>Select a return reason</em>
                      </MenuItem>
                      {
                        returnReasons?.values?.map((reason: LookupValue) => (
                          <MenuItem key={`${item.id}${reason.value}`} value={reason.value}>{reason.displayName?.[intl.locale]}</MenuItem>
                        ))
                      }
                    </Select>
                  </Box>
                </Box>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
