import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ordeReturnsApi, OrderToken } from '../store/orderReturnsApi';

const slice = createSlice({
  name: 'auth',
  initialState: { orderNumber: '', token: '' } as OrderToken,
  reducers: {
    setToken(state, action: PayloadAction<OrderToken>) {
      state.token = action.payload.token;
      state.orderNumber = action.payload.orderNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ordeReturnsApi.endpoints.getToken.matchFulfilled, (state, { payload }) => {
      state.token = payload.token;
      state.orderNumber = payload.orderNumber;
    });
  },
});

export default slice.reducer;

export const { setToken } = slice.actions

export const getToken = (state: any) => state.auth.token;
