import { RootState } from './../store';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createTheme } from '@mui/material/styles';

const placeholder = () => {
  throw new Error('Config not yet loaded. Please call config.js#loadConfig() and await resolution of the returned Promise.');
};

export let createComponentTheme: any = placeholder;
export let baseQuery: any = placeholder;
export let overrideTranslations: any = (locale) => {};
export const loadConfig = () =>
  fetch('/order-returns-config.json')
    .then((response) => response.json())
    .catch(() => {
      console.warn('Failed to load order-returns.json, falling back to dev defaults');
    })
    .then((config) => {
      createComponentTheme = () => createTheme(config?.theme ?? {});
      overrideTranslations = (locale) => config?.override?.translations[locale] ?? {};
      baseQuery = fetchBaseQuery({
        baseUrl: config?.serviceApiUrl.replace(/\/api$/gi,""),
        prepareHeaders: (headers, { getState }) => {
          const token = (getState() as RootState).auth.token

          // If we have a token set in state, let's assume that we should be passing it.
          if (token) {
            headers.set('x-access-token', `${token}`)
          }

          return headers;
        },
      });
    });
