import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderReturnItem } from '../store/orderReturnsApi';

type UpdateItemPayload = {
  item: OrderReturnItem,
  propertyName: string
  propertyValue: any
}

const orderItemsForReturnSlice = createSlice({
  name: 'orderItemsForReturn',
  initialState: [] as OrderReturnItem[],
  reducers: {
    setOrderReturnItems(state, action: PayloadAction<OrderReturnItem[]>) {
     return action.payload;
    },
    addOrderReturnItem(state, action: PayloadAction<OrderReturnItem>) {
      state.push(action.payload)
    },
    removeOrderReturnItem(state, action: PayloadAction<OrderReturnItem>) {
      return state.filter(item => item.id !== action.payload.id)
    },
    updateItemProperty(state, action: PayloadAction<UpdateItemPayload>) {
      const item = state.find(i => i.id === action.payload.item.id);
      if (item) {
        item[action.payload.propertyName] = action.payload.propertyValue;
      }
    },
  }
});

export default orderItemsForReturnSlice.reducer;
export const { setOrderReturnItems, addOrderReturnItem, removeOrderReturnItem, updateItemProperty } = orderItemsForReturnSlice.actions;
