import React from 'react';
import App from '../components/App';
import { createRoot } from 'react-dom/client';


import { createComponentTheme, loadConfig, overrideTranslations } from '../utils/config';

export class OrderReturnRequestElement extends HTMLElement {
  observer: MutationObserver;
  theme: any;
  props: string[] = ['locale'];
  root: any;

  constructor() {
    super();
    this.observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && this.props.includes(mutation.attributeName ?? '')) {
          this.update();
        }
      }
    });
    this.observer.observe(this, { attributes: true });
  }
  connectedCallback() {
    Promise.all([loadConfig()]).then(() => {
      this.theme = createComponentTheme();
      this.root = createRoot(this);
      this.mount();
    });
  }
  disconnectedCallback() {
    this.unmount();
    this.observer.disconnect();
  }
  update() {
    this.unmount();
    this.mount();
  }
  mount() {
    const locale = this.attributes['locale']?.value;
    const messages = overrideTranslations(locale);
    this.root.render(<App theme={this.theme} locale={locale} overrideMessages={messages} />);
  }

  unmount() {
    this.root.unmount()
  }
}
