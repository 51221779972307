import { defineMessages } from 'react-intl';


const sharedMessages = defineMessages({
  startReturnIntroMessage: {
    id: 'startReturnIntroMessage',
    defaultMessage: 'Start a Return Request'
  },
  startReturnButtonLabel: {
    id: 'startReturnButtonLabel',
    defaultMessage: 'Start a Return Request'
  }});

export default sharedMessages;
