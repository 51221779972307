import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Container, Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { OrderAuthenticationSuccess } from './Presentation/OrderAuthenticationSuccess';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useGetTokenMutation } from '../../store/orderReturnsApi';
import sharedMessages from '../../sharedMessages';
import { FormattedMessage } from 'react-intl';

export function OrderAuthentication() {
  const { enqueueSnackbar } = useSnackbar();
  const [getToken] = useGetTokenMutation();

  const [orderInfo, setOrderInfo] = useState({
    orderNumber: '',
    email: '',
    isAuthenticated: false,
  });
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();

  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const isOrderNumber = (orderNumber) => /^\d+$/.test(orderNumber);

  const handleOrderInfoChange = (event) => {
    setOrderInfo({ ...orderInfo, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    let errors: any = {};
    if (!isEmail(orderInfo.email)) {
      errors.email = 'Email address is not valid';
    }

    if (!isOrderNumber(orderInfo.orderNumber)) {
      errors.orderNumber = 'Only digits are allowed';
    }

    setErrors(errors);

    return !(Object.keys(errors).length > 0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    try {
      var data = await getToken({
        email: orderInfo.email,
        orderNumber: orderInfo.orderNumber,
      }).unwrap();
      if (data) {
        navigate(`/return/${orderInfo.orderNumber}`);
      }
    } catch (err: any) {
      enqueueSnackbar(err?.data?.message, {
        variant: 'error',
      });
    }
  };

  return (
    <div>
      <Container maxWidth="sm">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h2" align="center" gutterBottom>
              <FormattedMessage {...sharedMessages.startReturnIntroMessage} />
            </Typography>
            {!orderInfo.isAuthenticated && (
              <Box onSubmit={handleSubmit} method="POST" component="form"
                sx={{ margin: 'auto', flexDirection: 'column', width: '400px' }} display="flex" gap={2} >
                <div>
                  <Typography variant="body2" align="left" gutterBottom>
                    Enter the order number and the email address used for placing the order in the following form to initiate a return
                    request.
                  </Typography>
                </div>
                <div>
                  <TextField
                    label="Order Number"
                    variant="outlined"
                    name="orderNumber"
                    type="text"
                    error={errors.orderNumber}
                    helperText={errors.orderNumber}
                    fullWidth
                    value={orderInfo.orderNumber}
                    onChange={handleOrderInfoChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    name="email"
                    type="email"
                    error={errors.email}
                    helperText={errors.email}
                    fullWidth
                    value={orderInfo.email}
                    onChange={handleOrderInfoChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <Button variant="contained" type="submit"
                  color="primary" disabled={!orderInfo.orderNumber || !orderInfo.email}>
                  <FormattedMessage {...sharedMessages.startReturnButtonLabel} />
                </Button>
              </Box>
            )}
            {orderInfo.isAuthenticated && <OrderAuthenticationSuccess email={orderInfo.email} />}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
