import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

export function OrderAuthenticationSuccess({ email }) {
  return (
    <Box>
      <Typography variant="body2" align="left" gutterBottom>
        Thank you for using our Return Request service. You should receive an email shortly containing a link to start returning your items.
      </Typography>
      <Typography variant="body2" align="left" gutterBottom>
        The email was sent to <strong>{email}</strong>.
      </Typography>
    </Box>
  );
}
