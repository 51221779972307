import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { ordeReturnsApi } from './store/orderReturnsApi'
import authReducer from './reducers/authSlice'
import orderItemsForReturn from './reducers/orderItemsForReturnSlice'

//import { pokemonApi } from './services/pokemon'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [ordeReturnsApi.reducerPath]: ordeReturnsApi.reducer,
    auth: authReducer,
    orderItemsForReturn: orderItemsForReturn
    
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ordeReturnsApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>