import { baseSplitApi as api } from "./baseApi";
export const addTagTypes = ["Order"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getLookup: build.query<GetLookupApiResponse, GetLookupApiArg>({
        query: (queryArg) => ({
          url: `/api/lookups/orders/${queryArg.lookupName}`,
        }),
      }),
      getAvailableItems: build.query<
        GetAvailableItemsApiResponse,
        GetAvailableItemsApiArg
      >({
        query: () => ({ url: `/api/order/returns/getAvailableItems` }),
        providesTags: ["Order"],
      }),
      createOrderReturn: build.mutation<
        CreateOrderReturnApiResponse,
        CreateOrderReturnApiArg
      >({
        query: (queryArg) => ({
          url: `/api/order/returns`,
          method: "POST",
          body: queryArg.createOrderReturnRequest,
        }),
        invalidatesTags: ["Order"],
      }),
      calculateOrderReturnAmounts: build.mutation<
        CalculateOrderReturnAmountsApiResponse,
        CalculateOrderReturnAmountsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/order/returns/calculateAmounts`,
          method: "POST",
          body: queryArg.calculateOrderReturnAmountsRequest,
        }),
      }),
      getToken: build.mutation<GetTokenApiResponse, GetTokenApiArg>({
        query: (queryArg) => ({
          url: `/api/token/${queryArg.orderNumber}`,
          method: "POST",
          params: { email: queryArg.email },
        }),
        invalidatesTags: ["Order"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as ordeReturnsApi };
export type GetLookupApiResponse = /** status 200 Ok */ Lookup;
export type GetLookupApiArg = {
  lookupName: string;
};
export type GetAvailableItemsApiResponse =
  /** status 200 Ok */ GetItemsForOrderReturnResult;
export type GetAvailableItemsApiArg = void;
export type CreateOrderReturnApiResponse =
  /** status 200 Ok */ OrderReturnRequestResult;
export type CreateOrderReturnApiArg = {
  createOrderReturnRequest: CreateOrderReturnRequest;
};
export type CalculateOrderReturnAmountsApiResponse =
  /** status 200 Ok */ OrderReturnRequestResult;
export type CalculateOrderReturnAmountsApiArg = {
  calculateOrderReturnAmountsRequest: CalculateOrderReturnAmountsRequest;
};
export type GetTokenApiResponse = /** status 200 Ok */ OrderToken;
export type GetTokenApiArg = {
  /** order number */
  orderNumber: string;
  /** email */
  email: string;
};
export type LookupValue = {
  displayName?: {
    [key: string]: string;
  };
  id: string;
  isActive?: boolean;
  isSystem?: boolean;
  lookupId?: string;
  sortOrder?: number;
  value?: string;
};
export type Lookup = {
  description?: string;
  displayName?: {
    [key: string]: string;
  };
  isActive?: boolean;
  isSystem?: boolean;
  lookupName?: string;
  values?: LookupValue[];
};
export type ManagedOrderReturnItem = {
  additionalFeeTotalAmount?: number;
  adjustmentAmount?: number;
  amountRefunded?: number;
  created?: string;
  createdBy?: string;
  discountAmount?: number;
  id?: string;
  imageUrl?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  lineItemId?: string;
  merchandiseTotal?: number;
  productDiscount?: number;
  productDisplayName?: string;
  productId?: string;
  productPrice?: number;
  propertyBag?: {
    [key: string]: object;
  };
  quantity?: number;
  reason?: string;
  rejectReason?: string;
  sku?: string;
  status?: string;
  subTotal?: number;
  taxAmount?: number;
  taxTotal?: number;
  total?: number;
  variantId?: string;
  amountOfRefund?: number;
  availableQuantity?: number;
  unavailableReason?: string;
};
export type GetItemsForOrderReturnResult = {
  availableForReturn?: ManagedOrderReturnItem[];
  unavailableForReturn?: ManagedOrderReturnItem[];
};
export type OrderReturnItemAdditionalFee = {
  amount?: number;
  displayName?: {
    [key: string]: string;
  };
  name?: string;
  taxable?: boolean;
  totalAmount?: number;
};
export type OrderReturnItemTax = {
  code?: string;
  displayName?: {
    [key: string]: string;
  };
  isShippingFeeTax?: boolean;
  isShippingTax?: boolean;
  percentage?: number;
  taxAmount?: number;
  taxTotal?: number;
};
export type Address = {
  addressName?: string;
  city?: string;
  countryCode?: string;
  created?: string;
  createdBy?: string;
  email?: string;
  firstName?: string;
  id?: string;
  isPreferredBilling?: boolean;
  isPreferredShipping?: boolean;
  lastModified?: string;
  lastModifiedBy?: string;
  lastName?: string;
  latitude?: number;
  line1?: string;
  line2?: string;
  longitude?: number;
  notes?: string;
  phoneExtension?: string;
  phoneNumber?: string;
  postalCode?: string;
  propertyBag?: {
    [key: string]: object;
  };
  regionCode?: string;
};
export type OrderReturnCustomerTypeEnum =
  | "Registered"
  | "Guest"
  | "Unregistered";
export type OrderReturnDocument = {
  additionalNotes?: string;
  created?: string;
  createdBy?: string;
  id?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  name?: string;
  propertyBag?: {
    [key: string]: object;
  };
  publicUri?: string;
  type?: string;
};
export type OrderReturnItem = {
  additionalFeeTotalAmount?: number;
  adjustmentAmount?: number;
  amountRefunded?: number;
  created?: string;
  createdBy?: string;
  discountAmount?: number;
  id?: string;
  imageUrl?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  lineItemId?: string;
  merchandiseTotal?: number;
  productDiscount?: number;
  productDisplayName?: string;
  productId?: string;
  productPrice?: number;
  propertyBag?: {
    [key: string]: object;
  };
  quantity?: number;
  reason?: string;
  rejectReason?: string;
  sku?: string;
  status?: string;
  subTotal?: number;
  taxAmount?: number;
  taxTotal?: number;
  total?: number;
  variantId?: string;
};
export type OrderReturn = {
  additionalFeeTotal?: number;
  aggregatedAdditionalFees?: OrderReturnItemAdditionalFee[];
  aggregatedTaxes?: OrderReturnItemTax[];
  created?: string;
  createdBy?: string;
  customerAddress?: Address;
  customerId?: string;
  customerType?: OrderReturnCustomerTypeEnum;
  documents?: OrderReturnDocument[];
  entityVersion?: string;
  externalReferenceNumber?: string;
  id?: string;
  isFinished?: boolean;
  items?: OrderReturnItem[];
  lastModified?: string;
  lastModifiedBy?: string;
  notes?: string[];
  orderId?: string;
  orderNumber?: string;
  orderReturnNumber?: string;
  propertyBag?: {
    [key: string]: object;
  };
  scopeId?: string;
  status?: string;
  total?: number;
  totalAdjustment?: number;
  totalDiscount?: number;
  totalMerchandise?: number;
  totalRefunded?: number;
  totalSubtotal?: number;
  totalTax?: number;
  transactionOrderNumber?: number;
};
export type OrderReturnRequestResult = {
  orderReturn?: OrderReturn;
};
export type OrderReturnItemToAdd = {
  lineItemId?: string;
  propertyBag?: {
    [key: string]: object;
  };
  quantity?: number;
  reason?: string;
  status?: string;
};
export type CreateOrderReturnRequest = {
  customerAddress?: Address;
  externalReferenceNumber?: string;
  generateOrderReturnDocuments?: boolean;
  items?: OrderReturnItemToAdd[];
  notes?: string[];
  propertyBag?: {
    [key: string]: object;
  };
};
export type CalculateOrderReturnAmountsRequest = {
  items?: OrderReturnItem[];
  orderReturnNumber?: string;
};
export type OrderToken = {
  orderNumber: string;
  token: string;
};
export const {
  useGetLookupQuery,
  useGetAvailableItemsQuery,
  useCreateOrderReturnMutation,
  useCalculateOrderReturnAmountsMutation,
  useGetTokenMutation,
} = injectedRtkApi;
