import { Button, Grid, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { OrderReturnSummary } from './Presentation/OrderReturnSummary';
import { OrderItemsForReturn } from './Presentation/OrderItemsForReturn';
import { OrderReturnItem, useCalculateOrderReturnAmountsMutation, useGetAvailableItemsQuery } from '../../store/orderReturnsApi';
import {  setOrderReturnItems, updateItemProperty  } from '../../reducers/orderItemsForReturnSlice';
import { orderItemsForReturnSelector } from '../../selectors/orderItemsForReturn';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

export const OrderReturnRequest = () => {
  const { data, isLoading: isLoadingItems } = useGetAvailableItemsQuery();
  const allOrderItemsForReturn = useSelector(orderItemsForReturnSelector);
  const dispatch = useDispatch();
  const [calculateAmounts, calculateAmountsResult] = useCalculateOrderReturnAmountsMutation();

  const { orderNumber } = useParams();

  const availableItems = data?.availableForReturn ?? [];
  const unavailableItems = data?.unavailableForReturn?.map((i) => ({ ...i, isUnavailable: true })) ?? [];
  const allItems = availableItems.concat(unavailableItems);
  const itemsForReturn = allOrderItemsForReturn?.filter(i => i.quantity > 0);

  useEffect(() => {
    if (allOrderItemsForReturn && allOrderItemsForReturn.length) {
      calculateTotals();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrderItemsForReturn]);

  useEffect(() => {
    if (data) {
      dispatch(setOrderReturnItems(allItems));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSelectItem = (event) => {
    let id = event.target.value;
    let item = availableItems.find(i => i.id === id);
    if (!item) return;
    if (event.target.checked) {
      dispatch(updateItemProperty({item, propertyName: 'quantity', propertyValue: 1}));
    } else {
      dispatch(updateItemProperty({item, propertyName: 'quantity', propertyValue: 0}));
    }
  };

  const handleChangeItem = (item, propertyName, event) => {
    let propertyValue = event.target.value;
    dispatch(updateItemProperty({item, propertyName, propertyValue}));
  };

  const calculateTotals = async () => {
    calculateAmounts({
      calculateOrderReturnAmountsRequest: {
        items: itemsForReturn.filter(i => !i.isUnavailable).map(i => ({
          lineItemId: i.lineItemId,
          productId: i.productId,
          variantId: i.variantId,
          sku: i.sku,
          quantity: i.quantity,
          reason: i.reason
        } as OrderReturnItem)
        )
      },
    });
  };

  const previewOrderReturn = calculateAmountsResult.data?.orderReturn ?? {};

  return (
    <Container maxWidth="lg">
      <div>
        <Typography variant="h1" align="center" gutterBottom>
          Return for order #{orderNumber}
        </Typography>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h3" gutterBottom>
              Select your item(s) to return
            </Typography>
            <OrderItemsForReturn items={allOrderItemsForReturn} onSelected={handleSelectItem} onChanged={handleChangeItem} isLoading={isLoadingItems} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <OrderReturnSummary orderReturn={previewOrderReturn} isLoading={calculateAmountsResult.isLoading} />
            <Button variant="contained" color="primary" disabled={itemsForReturn.length === 0} sx={{ my: 2 }} fullWidth>
              Continue
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
