import './App.css';
import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import { ThemeProvider } from '@mui/material';
import { OrderReturnRequest } from './OrderReturns/OrderReturnRequest';
import { OrderAuthentication } from './OrderReturns/OrderAuthentication';
import { OrderToken } from './OrderTokenComponent';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { store } from '../store';

interface Props {
  theme?: any;
  locale?: any;
  overrideMessages?: object;
}

export default function App({ theme, locale, overrideMessages }: Props) {

  const messages = {...require("../translations/" + locale + ".json"), ...overrideMessages};

  return (
    <React.StrictMode>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Router>
                <div>
                  <Routes>
                    <Route path="/" element={<OrderAuthentication />}></Route>
                    <Route path="/return/:orderNumber" element={<OrderReturnRequest />}></Route>
                    <Route path="/token/:orderNumber/:token" element={<OrderToken />}></Route>
                  </Routes>
                </div>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </IntlProvider>
      </Provider>
    </React.StrictMode>
  );
}
